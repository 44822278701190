export interface ExistingOrder {
    orderId: string;
    orderRefNo: string;
    cusip: string;
    symbol: string;
    accountNumber: string;
    partyName: string;
    buySellCode: string;
    description: string;
    assettypecd: string;
    execQuantity: string;
    orderEntryDate: string;
    tradeDate: string;
    headeR_DATE: string;
    AdvisorId: string;
    reason: string;
    attestJson: CodeValue[];
    status: EXISTINGORDERSTATUS;
}

export interface NewAccount {
    accountNumber: string;
    isOver90Days: boolean;
    status?: NewAccountWorkflow;
    shortName?: string;
    clientName?: string;
    accountType?: string;
    registrationType?: string;
    teamName?: string;
    inceptionDate?: string;
    transitionFlag?: string;
    reviewId?: number;
    reviewType?: string;
    createdBy?: string;
    createdDate?: string;
    lastUpdatedBy?: string;
    lastUpdatedDate?: string;
    regBiaccountId?: number;
    reviewKey?: string;
    transactionType?: string;
    transactionAmount?: number;
    transactionDate?: string;
    event?: string;
    invest529Flag?: string;
}

export interface NewAccountsResponse {
    openCt: number;
    pendingCt: number;
    submittedCt: number;
    returnedBMCt: number;
    resubmittedCt: number;
    closedCt: number;
    returnedByCPCt: number;
    returnedToCPCt: number;
    skippedCt: number;
    accounts: NewAccount[];
    transactionTypes:string[];
    alertDetails: AlertDetails[];
    total:number;
}

export interface Trade {
    orderId: string;
    orderRefNo: string;
    cusip: string;
    symbol: string;
    securityLineDesc: string;
    buysellcode: string;
    tradeDate: string;
    settlementDate: string;
    tradeReferenceNo: string;
    quantity: number;
    price: number;
    principal: number;
    cancelcode: string;
    principalcorrected: number;
    accruedInterest: number;
    net: number;
    accountNumber: string;
    revenueCommissionAmount: number;
    revenueConcessionAmount: number;
}

export interface CodeValue {
    code: string;
    value: string;
    selected?: boolean;
}

export interface Note {
    note: string;
    who: string;
    when: string
}

export interface ReviewChkItem {
    code: string;
    desc: string;
    products: string[];
    buySellCode?: string;
}

export interface StatusHistory {
    newStatus: ORDERSTATUS;
    updateBy: string;
    updateOn: string;
}
export interface SearchCriteria {
    issuperuser: boolean;
    lstStatus: string[];
    lstrepid: string[];
    prdTypes: string[];
    fromdate?: Date;
    todate?: Date;
    prdNameContains: string;
    tradeSide: string;
    cusips: string[];
    prdIds: string[];
    actNo: string;
    pageSize?: number;
    pageNo?: number;
    orderByField: string;
    orderByDir: string;
    isFacet: boolean;
    alertCode?:string;
}

export interface ExistingOrdersResponse {
    totCt: number;
    attestCt: number;
    rejectedCt: number;
    noneCt: number;
    existingOrders: ExistingOrder[];
}


//ADV TREE

export class AdvisorHirerarchy {
    regions!: AdvisorRegion[];
}
export class AdvisorRegion {
    regName!: string;
    hubs!: AdvisorHub[];
    isInc: boolean;
    constructor() {
        this.isInc = true;
    }
}
export class AdvisorHub {
    hubName!: string;
    teams!: AdvisorTeam[];
    isInc: boolean;
    constructor() {
        this.isInc = true;
    }
}
export class AdvisorTeam {
    teamName!: string;
    advisors!: AdvNameId[];
    isInc: boolean;
    constructor() {
        this.isInc = true;
    }
}

export class AdvNameId {
    repId!: string;
    repName!: string;
    isInc: boolean;
    constructor() {
        this.isInc = true;
    }
}

export class TodoItemNode {
    children!: TodoItemNode[];
    label!: string;
    id!: string;
    isChecked!: boolean;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
    label!: string;
    level!: number;
    expandable!: boolean;
    id!: string;
    isChecked!: boolean;
}

//ADV TREE END
//ENUMS

export enum USERROLE {
    NULL = "NULL",
    FA = "FA",
    BM = "BM",
    HO = "HO",
    OPS = "OPS",
    SUPER = "SUPER",
    PO = 'PO',
    COMP = 'COMP',
    SUPERVISION = 'SUPERVISION'
}

export enum PRODUCTTYPE {
    AI = 'AI', //ALTERNATIVE INTVESTMENTS
    SA = 'SA', //SPECIALITY ASSETS
    SP = 'SP', //STRUCTURED PRODUCTS
    PREF = 'PREF'
}

export enum SHARECLASS {
    BROKERAGE = 'BROKERAGE',
    ADVISORY = 'ADVISORY'
}

export enum ORDERSTATUS {
    ACKNOWLEDGED = 'ACKNOWLEDGED',
    RETURNED = 'RETURNED',
    SUBMITTED = 'SUBMITTED',
    DRAFT = 'DRAFT',
    INPROCESS = 'INPROCESS'
}
export enum CLIENTSUITABILITY {
    QUALIFIED = 'QUALIFIED'
}

export enum ENTITYSUITABILITY {
    QUALIFIED = 'QUALIFIED'
}

export enum EXISTINGORDERSTATUS {
    NONE = 'NONE',
    ATTESTED = 'ATTESTED',
    SKIPPED = 'SKIPPED'
}

export enum ORDERSOURCE {
    NONE = 'NONE',
    RCM = 'RCM',
    IMPORT = 'IMPORT'
}

export enum QUEUESTATUS {
    NONE = 'NONE',
    PENDING = 'PENDING',
    INVALID = 'INVALID',
    PROCESSING = 'PROCESSING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE'
}

export enum NewAccountWorkflow {
    OPEN = 'OPEN',
    // DBDREVIEW = 'DBDREVIEW',
    // REOPENED = 'REOPENED',
    PENDING = 'PENDING', // Accounts that are not reviewed / acted upon(Default)
    SUBMITTED = 'SUBMITTED', // Attested by PW
    RETURNEDBM = 'RETURNED-BM', // Returned by DBD
    RESUBMITTED = 'RESUBMITTED', // Re-submitted by Advisor
    CLOSED = 'CLOSED', // Accounts that are reviewed and closed
    RETURNEDBYCP = 'RETURNED-BY-CP', // Returned by Control Person 
    RETURNEDTOCP = 'RETURNED-TO-CP', // Returned to Control Person
    SKIPPED = 'SKIPPED' // Accounts that are skipped by Supervision team
}

export interface NewAccountQuestion {
    code: string;              // Code
    text?: string;              // Actual Question Text
    answers?: NewAccountCodeValue[];
    singleAnswer?: boolean;     // If its checkbox or Radio
    nextQuestionCode?: string;  // This is populated where next code DOESN'T depends on Individual answer. For Checkbox only. Not Radio
    onlyNotes?: boolean;        // This is for Question without predefined answer. 
    showHistory?: boolean;
    allowUpload?: boolean;
    htmlText?: string;

}

export interface Workflow {
    id?: string;
    sets?: QuestionSet[];
}

export interface QuestionSet {
    statusFor: string;
    questions: NewAccountQuestion[];
}
export interface ExisitingResponse {
    createdBy?: string;
    createdDate?: string;
    lastUpdatedBy?: string;
    lastUpdatedDate?: string;
    reviewId?: number;
    status?: string;
    responseList?: ExisitingResponseDetails[];
    groupedResponse?: GroupedExisitingResponseDetails[];
    documentList?: Document[];
}

export interface ExisitingResponseDetails {
    attestationCode?: string;
    attestationDesc?: string;
    responseCode?: string;
    responseDetail?: string;
}

export interface GroupedExisitingResponseDetails {
    attestationCode?: string;
    attestationDesc?: string;
    ans?: any[]
}

export interface NewAccountCodeValue {
    code: string;              // Code
    value?: string;             // Text of the Answer
    selected?: boolean | string;// Need to go back and see if selected will be enough for other value
    otherValue?: string;        // Other text answer input by 
    isOther?: boolean;          // Boolean 
    nextQuestionCode?: string;  // This is populated where next code depends on Individual answer. For Radio Only. Not checkbox
    nextStatus?: string;    // Similar. Now code can decide status
    docRequired?: boolean;
    htmlValue?: string;
    headerHtmltext?: string;
}

export interface NewAccountAttestPayload {
    accountNumbers: string[];
    status?: string;
    responses?: any[];
    reviewId?: number;
    reviewType: string;
    createdBy?: string;
    createdDate?: string;
    reviewKeys: any[];
    documents?: Document[];
    onlyComments?: boolean;
    userRole?: string;
}

export interface Document {
    documentId?: string;
    documentName?: string;
}

export interface AlertDetails{
    alertCode:string;
    alertCount:number;
    fidAlertColor:string;
}

export const NEWACCT = 'NEWACCT';
export const TRANSITION = 'TRANSITION';
export const DOL = 'PTEACCT';
export const FUNDING = 'FUNDING';
export const NEWACCT_529 = 'NEWACCT_529'

export const ORDER_AI_BUY = 'ORDER_AI_BUY';
export const ORDER_AI_SELL = 'ORDER_AI_SELL';
export const ORDER_UT_BUY = 'ORDER_UT_BUY';
export const ORDER_UT_SELL = 'ORDER_UT_SELL';
export const ORDER_SP_SELL = 'ORDER_SP_SELL';
export const ORDER_SP_BUY = 'ORDER_SP_BUY';
export const ORDER_MF_BUY = 'ORDER_MF_BUY';
export const ORDER_MF_SELL = 'ORDER_MF_SELL';
export const ORDER_CM_BUY = 'ORDER_CM_BUY';
export const ORDER_CM_SELL = 'ORDER_CM_SELL';
export const ORDER_529 = 'ORDER_529';

export const ORDER_AI_BUY_RET = 'ORDER_AI_BUY_RET';
export const ORDER_AI_SELL_RET = 'ORDER_AI_SELL_RET';
export const ORDER_UT_BUY_RET = 'ORDER_UT_BUY_RET';
export const ORDER_UT_SELL_RET = 'ORDER_UT_SELL_RET';
export const ORDER_SP_SELL_RET = 'ORDER_SP_SELL_RET';
export const ORDER_SP_BUY_RET = 'ORDER_SP_BUY_RET';
export const ORDER_MF_BUY_RET = 'ORDER_MF_BUY_RET';
export const ORDER_MF_SELL_RET = 'ORDER_MF_SELL_RET';
export const ORDER_CM_BUY_RET = 'ORDER_CM_BUY_RET';
export const ORDER_CM_SELL_RET = 'ORDER_CM_SELL_RET';
export const ORDER_529_RET = 'ORDER_529_RET';

export const ORDER_AI_BUY_NONRET = 'ORDER_AI_BUY_NONRET';
export const ORDER_AI_SELL_NONRET = 'ORDER_AI_SELL_NONRET';
export const ORDER_UT_BUY_NONRET = 'ORDER_UT_BUY_NONRET';
export const ORDER_UT_SELL_NONRET = 'ORDER_UT_SELL_NONRET';
export const ORDER_SP_SELL_NONRET = 'ORDER_SP_SELL_NONRET';
export const ORDER_SP_BUY_NONRET = 'ORDER_SP_BUY_NONRET';
export const ORDER_MF_BUY_NONRET = 'ORDER_MF_BUY_NONRET';
export const ORDER_MF_SELL_NONRET = 'ORDER_MF_SELL_NONRET';
export const ORDER_CM_BUY_NONRET = 'ORDER_CM_BUY_NONRET';
export const ORDER_CM_SELL_NONRET = 'ORDER_CM_SELL_NONRET';
export const ORDER_529_NONRET = 'ORDER_529_NONRET';

export interface ExistingOrdersResponseV2 {
    openCt: number;
    pendingCt: number;
    submittedCt: number;
    returnedBMCt: number;
    resubmittedCt: number;
    closedCt: number;
    returnedByCPCt: number;
    returnedToCPCt: number;
    skippedCt: number;
    existingOrders: ExistingOrderV2[];
    alertDetails: AlertDetails[];
    total:number;
}

export interface ExistingOrderV2 {
    orderId: string;
    orderRefNo: string;
    cusip: string;
    symbol: string;
    accountNumber: string;
    partyName: string;
    buySellCode: string;
    description: string;
    assettypecd: string;
    execQuantity: string;
    orderEntryDate: string;
    tradeDate: string;
    headeR_DATE: string;
    AdvisorId: string;
    // reason: string;
    // attestJson: CodeValue[];
    // status: EXISTINGORDERSTATUS;
    reviewId?: number;
    reviewType?: string;
    createdBy?: string;
    createdDate?: string;
    lastUpdatedBy?: string;
    lastUpdatedDate?: string;
    reviewKey?: string;
    status?: NewAccountWorkflow;
    principalCorrected: number;
    advisorname?: string;
    registrationType?: string;

}